import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Text, Title } from "@nerdwallet/react-typography"

import Box from "@src/components/Box"
import Dropdown from "@src/components/Dropdown"
import Columns from "@src/components/Columns/Columns"
import Column from "@src/components/Columns/Column"
import Button from "@src/components/Button"
import Image from "@nerdwallet/react-image"

import creditCardHand from "./credit-card-hand.svg"

import {
  PREFERENCE_LABEL,
  PREFERENCE_OPTIONS,
  CREDIT_SCORE_LABEL,
  CREDIT_SCORE_OPTIONS,
  HEADER_LABEL,
  SUBMIT_LABEL,
  ON_SECURE_SITE,
  CTA_TYPE,
  URI,
  ERROR_MESSAGE,
} from "./constants"

import styles from "./InfoGatheringCTA.module.less"

const validate = ({
  requireFields,
  loanAmount,
  creditScore,
  setLoanAmountErrorMessage,
  setCreditScoreErrorMessage,
}) => {
  let valid = true
  if (!requireFields) return true

  if (!loanAmount) {
    setLoanAmountErrorMessage(ERROR_MESSAGE)
    valid = false
  }

  if (!creditScore) {
    setCreditScoreErrorMessage(ERROR_MESSAGE)
    valid = false
  }
  return valid
}

const InfoGatheringCTA = props => {
  const { requireFields } = props
  const [loanAmount, setLoanAmount] = useState("")
  const [creditScore, setCreditScore] = useState("")
  const [loanAmountErrorMessage, setLoanAmountErrorMessage] = useState("")
  const [creditScoreErrorMessage, setCreditScoreErrorMessage] = useState("")

  //Clear error message
  useEffect(() => {
    setLoanAmountErrorMessage()
  }, [loanAmount])

  useEffect(() => {
    setCreditScoreErrorMessage()
  }, [creditScore])

  const onSubmitForm = e => {
    e.preventDefault()
    const isValid = validate({
      requireFields,
      loanAmount,
      creditScore,
      setLoanAmountErrorMessage,
      setCreditScoreErrorMessage,
    })
    if (isValid) window.open(URI, "_blank").focus()
  }

  return (
    <Box className={styles.box}>
      <form
        id="info-gathering-form"
        action={URI}
        method="get"
        onSubmit={onSubmitForm}
        data-cta-type={CTA_TYPE}
      >
        <Columns>
          <Column width={{ desktop: 6, mobile: 12 }} alignEnd>
            <Image
              alt="credit card on hard"
              backgroundColor="transparent"
              className={styles.imageContainer}
              imageClassName={styles.image}
              height={360}
              width={173}
              src={creditCardHand}
              lazy
            />
          </Column>
          <Column width={{ desktop: 6, mobile: 12 }}>
            <div className={styles.formContainer}>
              <Title size={2} className={styles.header} bold>
                {HEADER_LABEL}
              </Title>
              <div className={styles.dropdown}>
                <Dropdown
                  analyticsTag="Info Gathering Select"
                  header={PREFERENCE_LABEL}
                  smallLegend
                  boldLegend={false}
                  options={PREFERENCE_OPTIONS}
                  placeholder="Select your option"
                  setUpdateAction={setLoanAmount}
                />
                {loanAmountErrorMessage && (
                  <span className={styles.error}>{loanAmountErrorMessage}</span>
                )}
              </div>
              <div className={styles.dropdown}>
                <Dropdown
                  analyticsTag="Info Gathering Select"
                  header={CREDIT_SCORE_LABEL}
                  smallLegend
                  boldLegend={false}
                  options={CREDIT_SCORE_OPTIONS}
                  placeholder="Select your option"
                  setUpdateAction={setCreditScore}
                />
                {creditScoreErrorMessage && (
                  <span className={styles.error}>
                    {creditScoreErrorMessage}
                  </span>
                )}
              </div>

              <Button
                type="submit"
                primary
                loading={false}
                className={classNames("clickable", styles.button)}
                data-nw-mp
                data-cta-type={CTA_TYPE}
                rel="sponsored"
                aria-label="Form Info-Gathering CTA"
                formaction={URI}
              >
                {SUBMIT_LABEL}
              </Button>
              <Text small color="neutral-darker" className={styles.onWebsite}>
                {ON_SECURE_SITE}
              </Text>
            </div>
          </Column>
        </Columns>
      </form>
    </Box>
  )
}

InfoGatheringCTA.propTypes = {
  requireFields: PropTypes.bool,
}

InfoGatheringCTA.defaultProps = {
  requireFields: false,
}

const InfoGatheringCTAWithUrl = props => {
  return <InfoGatheringCTA {...props} data-cta-type={CTA_TYPE} />
}

export default InfoGatheringCTAWithUrl
