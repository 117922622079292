const PREFERENCE_LABEL = "I care about"
const PREFERENCE_OPTIONS = [
  { key: "Cashback rewards", value: "Cashback rewards" },
  { key: "Balance transfer cards", value: "Balance transfer cards" },
  { key: "No annual fee", value: "No annual fee" },
  { key: "Industry-specific cards", value: "Industry-specific cards" },
]

const CREDIT_SCORE_LABEL = "What is your personal credit score"
const CREDIT_SCORE_OPTIONS = [
  { key: "300-629: Bad credit", value: "300-629: Bad credit" },
  { key: "630-689: Average credit", value: "630-689: Average credit" },
  { key: "690-719: Good credit", value: "690-719: Good credit" },
  { key: "720-850: Excellent credit", value: "720-850: Excellent credit" },
]

const HEADER_LABEL = "What are you looking for in your business credit card?"
const SUBMIT_LABEL = "Find your credit card"
const ON_SECURE_SITE = "on Nerdwallet's secure site"
const CTA_TYPE = "Info-Gathering CTA"
const URI = "/best/credit-cards/small-business"
const ERROR_MESSAGE = "Required"

export {
  PREFERENCE_LABEL,
  PREFERENCE_OPTIONS,
  CREDIT_SCORE_LABEL,
  CREDIT_SCORE_OPTIONS,
  HEADER_LABEL,
  SUBMIT_LABEL,
  ON_SECURE_SITE,
  CTA_TYPE,
  URI,
  ERROR_MESSAGE,
}
