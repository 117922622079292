import React from "react"

const DropdownIcon = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      alt="Arrow Icon"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.85255 1.84912C10.1675 1.53414 9.94445 0.995568 9.499 0.995568L1.49013 0.995567C1.04468 0.995567 0.821597 1.53414 1.13658 1.84912L5.14101 5.85355C5.33627 6.04882 5.65286 6.04882 5.84812 5.85355L9.85255 1.84912Z"
        fill="black"
      />
    </svg>
  )
}

export default DropdownIcon
