import React from "react"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import Column from "@src/components/Columns/Column"
import Columns from "@src/components/Columns/Columns"
import LoremIpsum from "@src/components/LoremIpsum"
import InfoGatheringCTA from "@src/components/InfoGatheringCTA"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import { INSURANCE_TOPICS } from "@src/lib/constants"

const InfoGatheringCTAPage = () => {
  const title = "Info Gathering CTA test page"
  const breadcrumb = {
    page: {
      title,
    },
    slug: "SMALL_BUSINESS",
  }
  return (
    <ApplyUrlProvider
      location={{ pathname: "/info-gathering-cta" }}
      attributionTopic={INSURANCE_TOPICS[0]}
    >
      <Page
        title={title}
        header={<Header />}
        headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
        footer={<Footer />}
      >
        <Columns>
          <Column width={{ desktop: 8, mobile: 12 }}>
            <LoremIpsum />
            <span>Require Questions</span>
            <InfoGatheringCTA requireFields />
            <span>Doesn&apos;t Require Questions</span>
            <InfoGatheringCTA />
            <LoremIpsum />
          </Column>
          <Column width={{ desktop: 4, mobile: 12 }}>[right rail]</Column>
        </Columns>
      </Page>
    </ApplyUrlProvider>
  )
}

export default InfoGatheringCTAPage
