import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Text } from "@nerdwallet/react-typography"
import DropdownIcon from "./DropdownIcon"
import styles from "./Dropdown.module.less"
import { useOutsideClickHandler } from "@src/hooks/useOutsideClickHandler"

const SELECT_KEYS = ["Enter", " "]

const Dropdown = props => {
  const {
    header,
    options,
    selectedOption,
    setUpdateAction,
    placeholder,
    analyticsTag,
    boldLegend,
    smallLegend,
    rightAligned,
  } = props
  const [current, setCurrent] = useState(
    selectedOption || { key: placeholder, value: placeholder }
  )
  const [open, setOpen] = useState(false)

  const handleClickSelect = () => {
    setOpen(!open)
  }

  const handlePressSelect = event => {
    if (SELECT_KEYS.indexOf(event.key) > -1) {
      handleClickSelect()
      event.preventDefault()
      return
    }

    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      const idx = options.findIndex(opt => opt.key === current.key)
      const prevOption =
        event.key === "ArrowUp"
          ? options[idx - 1] || options[options.length - 1]
          : options[idx + 1] || options[0]
      handleClickOption(prevOption)
      event.preventDefault()
      return
    }
  }

  const handleClickOption = option => {
    setUpdateAction(option.key)
    setCurrent(option)
    setOpen(false)
  }

  const handlePressOption = (event, option) => {
    if (SELECT_KEYS.indexOf(event.key) > -1) {
      handleClickOption(option)
    }
  }

  const wrapperRef = useRef(null)
  useOutsideClickHandler(wrapperRef, () => setOpen(false))

  return (
    <fieldset
      ref={wrapperRef}
      className={classNames(styles.fieldset, {
        [styles.rightAlignedFieldset]: rightAligned,
      })}
    >
      {header && (
        <legend>
          <Text small={smallLegend} bold={boldLegend}>
            {header}
          </Text>
        </legend>
      )}
      <div className={styles.container}>
        <div
          className={classNames(styles.box, "clickable")}
          data-nw-dropdown-type={analyticsTag}
          data-nw-mp
          onClick={handleClickSelect}
          onKeyDown={handlePressSelect}
          tabIndex="0"
          role="button"
          aria-pressed={open}
        >
          <div className={styles.value}>
            <p
              className={classNames(styles.text, {
                [styles.placeholder]: current.value === placeholder,
              })}
            >
              {current.value}
            </p>
          </div>

          <span
            className={classNames(styles.icon, {
              [styles.openIcon]: open,
            })}
            aria-hidden="true"
          >
            <DropdownIcon />
          </span>
        </div>

        <ul
          className={classNames(styles.list, {
            [styles.openList]: open,
            [styles.rightAlignedList]: rightAligned,
          })}
        >
          {options.map(option => (
            <li
              className={classNames(styles.option, "clickable", {
                [styles.selected]: option.key === current.key,
              })}
              data-nw-dropdown-option={option.key}
              data-nw-mp
              key={option.key}
              onClick={() => handleClickOption(option)}
              onKeyDown={e => handlePressOption(e, option)}
              tabIndex="0"
            >
              <label
                className={styles.label}
                htmlFor={option.key}
                role="button"
                aria-pressed={option.key === current.key}
              >
                {option.value}
              </label>
            </li>
          ))}
        </ul>
      </div>
    </fieldset>
  )
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
  header: PropTypes.string,
  placeholder: PropTypes.string,
  selectedOption: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  setUpdateAction: PropTypes.func.isRequired,
  analyticsTag: PropTypes.string,
  boldLegend: PropTypes.bool,
  smallLegend: PropTypes.bool,
  rightAligned: PropTypes.bool,
}

Dropdown.defaultProps = {
  header: null,
  placeholder: "Select Option",
  selectedOption: null,
  analyticsTag: "Select",
  boldLegend: true,
  smallLegend: false,
  rightAligned: false,
}

export default Dropdown
